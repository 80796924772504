import React from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import { AppImage } from "../components";
import { Link } from "gatsby";
import slugify from "../utils/slugify";
import Truncate from "react-truncate";
import { MissingAuthorIcon, MissingHeroImage } from "../components";
import processDate from "../utils/processDate";

import { Layout } from "../components";

const Blog = ({ data }) => {
  const currentBlogArray = _.orderBy(
    _.filter(_.get(data, "allContentfulBlog.nodes"), (blog) => {
      return !blog.comingSoon;
    }),
    "date",
    "desc"
  );

  const comingSoonBlogArray = _.orderBy(
    _.filter(_.get(data, "allContentfulBlog.nodes"), (blog) => {
      return blog.comingSoon;
    }),
    "date",
    "desc"
  );

  const generateBlogArticles = (blogArray) => {
    return blogArray.map((blog, index) => {
      const title = _.get(blog, "title.internal.content");
      const authorName = _.get(blog, "author.name");
      const date = _.get(blog, "date");
      const readTime = _.get(blog, "readTime");
      const imageHasText = _.get(blog, "imageHasText");
      const comingSoon = _.get(blog, "comingSoon");

      const renderBlog = (
        <>
          <div className="blog-image-container">
            {_.get(blog, "image") ? (
              <AppImage className="blog-image" image={_.get(blog, "image")} />
            ) : (
              <MissingHeroImage />
            )}
          </div>
          <div className="content-wrapper" role="none">
            <h2 className="title-wrapper">
              <Truncate lines={3} trimWhiteSpace>
                {title}
              </Truncate>
            </h2>
            <div className="blog-info">
              {(_.get(blog, "authors") || []).map((author, index) => {
                if (_.get(author, "image")) {
                  return (
                    <div key={index} className="author-image-container">
                      <AppImage image={_.get(author, "image")} />
                    </div>
                  );
                } else {
                  return (
                    <div key={index} className="author-image-container">
                      <MissingAuthorIcon />
                    </div>
                  );
                }
              })}
              <div className="small">
                <div>
                  {(_.get(blog, "authors") || []).map((author, index) => {
                    return `${_.get(author, "name")}${
                      index < _.get(blog, "authors").length - 1 ? " and " : ""
                    }`;
                  })}
                </div>
                {date ? processDate(date) : null}
                {readTime ? ` • ${readTime}` : ""}
              </div>
            </div>
          </div>
        </>
      );
      return (
        <div
          className={`blog-post${
            (index + 1) % 4 === 1 ? " four-remainder-one" : ""
          }${(index + 1) % 4 === 2 ? " four-remainder-two" : ""}${
            (index + 1) % 4 === 3 ? " four-remainder-three" : ""
          }${(index + 1) % 4 === 0 ? " four-remainder-zero" : ""}${
            (index + 1) % 9 === 1 ? " nine-remainder-one" : ""
          }${(index + 1) % 9 === 2 ? " nine-remainder-two" : ""}${
            (index + 1) % 9 === 3 ? " nine-remainder-three" : ""
          }${(index + 1) % 9 === 4 ? " nine-remainder-four" : ""}${
            (index + 1) % 9 === 5 ? " nine-remainder-five" : ""
          }${(index + 1) % 9 === 6 ? " nine-remainder-six" : ""}${
            (index + 1) % 9 === 7 ? " nine-remainder-seven" : ""
          }${(index + 1) % 9 === 8 ? " nine-remainder-eight" : ""}${
            (index + 1) % 9 === 0 ? " nine-remainder-zero" : ""
          }${imageHasText ? " image-has-text" : ""}`}
          key={index}
        >
          {!comingSoon ? (
            <Link to={`/blog-post/${slugify(title)}/`}>{renderBlog}</Link>
          ) : (
            renderBlog
          )}
        </div>
      );
    });
  };

  return (
    <Layout className="blog-page" pageData={_.get(data, "contentfulPageData")}>
      <section className="container flex tablet">
        <div className="blog-row">{generateBlogArticles(currentBlogArray)}</div>
      </section>
      <section className="container">
        <h2 className="coming-soon-title">Coming Soon</h2>
        <div className="blog-row">
          {generateBlogArticles(comingSoonBlogArray)}
        </div>
      </section>
    </Layout>
  );
};

export default Blog;

export const query = graphql`
  query blogQuery {
    contentfulPageData(title: { eq: "Blog" }) {
      title
      description
      image {
        file {
          url
        }
      }
      header
    }
    allContentfulBlog {
      nodes {
        image {
          title
          description
          fluid(maxWidth: 720, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        imageHasText
        comingSoon
        title {
          internal {
            content
          }
        }
        authors {
          name
          image {
            fluid(maxWidth: 39, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        date
        readTime
      }
    }
  }
`;
